import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

export enum IdentityVerificationStatus {
  NotAttempted = 0,
  Requested = 1,
  Pending = 2,
  Passed = 3,
  ReviewRequired = 4,
  Failed = 5,
  Unavailable = 6,
}

@Component({
  selector: 'app-identity-verification-banner',
  templateUrl: './identity-verification-banner.component.html',
  styleUrls: ['./identity-verification-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdentityVerificationBannerComponent {
  @Input() verificationStatus: IdentityVerificationStatus = IdentityVerificationStatus.Pending;
  @Input() verificationUrl: IdentityVerificationStatus = IdentityVerificationStatus.Pending;

  get message(): string {
    switch (this.verificationStatus) {
      case IdentityVerificationStatus.Failed:
        return 'We have identified multiple issues with your identity verification attempt. Any pending payments are currently suspended. If you think this was done by mistake, please reach out to our Support team.';
      case IdentityVerificationStatus.ReviewRequired:
        return 'We are reviewing your identity verification information.';
      case IdentityVerificationStatus.Requested:
      case IdentityVerificationStatus.Pending:
        return 'Our team would like you to verify your identity. To prevent a reduction in project invites, please complete this as soon as you can.';
      default:
        return '';
    }
  }

  get showVerificationLink(): boolean {
    return this.verificationUrl && (this.verificationStatus === IdentityVerificationStatus.Requested
      || this.verificationStatus === IdentityVerificationStatus.Pending);
  }

  get shouldShow(): boolean {
    return this.verificationStatus !== IdentityVerificationStatus.NotAttempted
      && this.verificationStatus !== IdentityVerificationStatus.Passed
      && this.verificationStatus !== IdentityVerificationStatus.Unavailable;
  }
}
