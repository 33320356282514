export default `
<label id="customDropdownLabel">Sort by</label>
<div class="filter">
  <button id="customDropdownBtn" ng-click="$ctrl.toggleDropdown()" readonly type="text" ng-model="$ctrl.label" placeholder="" class="filter-Val clickable" aria-haspopup="true" aria-labelledby="customDropdownLabel customDropdownBtn" aria-expanded="{{$ctrl.dropdownShown}}">
    <div>{{$ctrl.label}}</div>
  </button>
  <label ng-click="$ctrl.toggleDropdown()" class="noselect filter-Toggle"></label>
  <ul class="filter-options" ng-show="$ctrl.dropdownShown" role="listbox" aria-hidden="{{!$ctrl.dropdownShown}}" aria-labelledby="customDropdownLabel" tabindex="-1">
      <li id="filter-option-{{$index}}" class="filter-options__item" ng-click="$ctrl.setValue(value)" ng-keydown="$ctrl.handleOptionKeydown($event, value)" ng-repeat="(value, label) in $ctrl.options" ng-class="{'selected' : (label == $ctrl.label)}" ng-blur="$ctrl.handleOnBlur($event)" role="option" tabindex="-1">{{label}}</li>
  </ul>
</div>
`;
