export default `
<footer class="unauthenticated-footer-new" role="contentinfo">
  <div class="unauthenticated-footer-new__container">
    <div class="unauthenticated-footer-new__top">
      <div class="unauthenticated-footer-new__logo">
        <a href="/" rel="noopener noreferrer">
          <img alt="uTest" src="https://s3.us-east-1.amazonaws.com/utest.com/assets/resources/img/u-test-logo-white.svg">
        </a>
      </div>
      <div class="unauthenticated-footer-new__social-cta unauthenticated-nav-bar-new--hidden-desktop-md unauthenticated-nav-bar-new--hidden-desktop-sm unauthenticated-nav-bar-new--hidden-tablet">Follow Us On Social</div>
      <div class="unauthenticated-footer-new__social">
        <div class="unauthenticated-footer-new__social-cta unauthenticated-nav-bar-new--hidden-mobile">Follow Us On Social</div>
        <div class="unauthenticated-footer-new__social-links">
          <a class="unauthenticated-footer-new__social-link" href="https://twitter.com/utest" target="_blank">
            <img src="https://s3.us-east-1.amazonaws.com/utest.com/assets/img/twitter.svg" alt="X">
          </a>
          <a class="unauthenticated-footer-new__social-link" href="https://www.instagram.com/utestinc/" target="_blank" title="Follow on Instagram">
            <img src="https://s3.us-east-1.amazonaws.com/utest.com/assets/img/instagram.svg" alt="Instagram">
          </a>
          <a class="unauthenticated-footer-new__social-link" href="https://www.facebook.com/utest" target="_blank">
            <img src="https://s3.us-east-1.amazonaws.com/utest.com/assets/img/facebook.svg" alt="Facebook">
          </a>
          <a class="unauthenticated-footer-new__social-link" href="https://www.linkedin.com/company/utest" target="_blank">
            <img src="https://s3.us-east-1.amazonaws.com/utest.com/assets/img/linkedin.svg" alt="LinkedIn">
          </a>
          <a class="unauthenticated-footer-new__social-link" href="https://www.reddit.com/r/UTEST/" target="_blank">
            <img src="https://s3.us-east-1.amazonaws.com/utest.com/assets/img/reddit.svg" alt="Reddit">
          </a>
        </div>
      </div>
    </div>
    <div class="unauthenticated-footer-new__copyright unauthenticated-nav-bar-new--hidden-tablet unauthenticated-nav-bar-new--hidden-desktop-sm unauthenticated-nav-bar-new--hidden-desktop-md ">
      © {{$ctrl.year}} Applause App Quality, Inc.
    </div>
    <div class="unauthenticated-footer-new__nav">
      <div class="unauthenticated-footer-new__copyright unauthenticated-nav-bar-new--hidden-tablet unauthenticated-nav-bar-new--hidden-mobile">
        © {{$ctrl.year}} Applause App Quality, Inc.
      </div>
      <nav aria-label="Footer Navigation">
        <ul class="unauthenticated-footer-new__nav-items">
          <li class="unauthenticated-footer-new__nav-item unauthenticated-nav-bar-new--hidden-desktop-sm unauthenticated-nav-bar-new--hidden-desktop-md unauthenticated-nav-bar-new--hidden-mobile">
            © {{$ctrl.year}} Applause App Quality, Inc.
          </li>
          <li class="unauthenticated-footer-new__nav-item">
            <a href="/utest-guidelines">uTest Guidelines</a>
          </li>
          <li class="unauthenticated-footer-new__nav-item">
            <a href="/privacy-policy">Privacy Policy</a>
          </li>
          <li class="unauthenticated-footer-new__nav-item">
            <a href="/terms-and-conditions">Terms & Conditions</a>
          </li>
          <li class="unauthenticated-footer-new__nav-item--onetrust">
            <!-- OneTrust Cookies Settings button start -->
            <button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</button>
            <!-- OneTrust Cookies Settings button end -->
          </li>
          <li class="unauthenticated-footer-new__nav-item">
            <a href="/phishing-notice">Phishing Notice</a>
          </li>
          <li class="unauthenticated-footer-new__nav-item">
            <a href="https://support.utest.com/csp" target="_blank">Help & Support</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>

</footer>
`;
