export default `
<div id="mainContent">
  <div class="profile-cover" bg-img="{{user.profile_banner.url}}">
    <div class="profile-cover-content container">
      <div class="reverse-follow" ng-if="user.follow_stats.follows_current_user">Follows you</div>
      <div class="cover-content-btns">
        <button class="cover-content-btn icon-at hidden-mobile" title="Tag this user in a Status Update" ng-if="currentUser.current_user_permissions['create_status'] && !isLoggedInUser" ng-click="toggleQuickAdd()" aria-label="Mention">
          <i class="material-icons" aria-hidden="true">alternate_email</i>
        </button>
        <button class="cover-content-btn icon-at visible-mobile" title="Tag this user in a Status Update" ng-if="currentUser.current_user_permissions['create_status'] && !isLoggedInUser" write-status-fs-trigger status="mentionStatus" aria-label="Mention">
          <i class="material-icons" aria-hidden="true">alternate_email</i>
        </button>
        <div class="cover-content-btn" ng-if="!currentUser.current_user_permissions['create_status']"></div>
        <div avatar-badges="user" class="profile-thumb xlarge" ng-if="!isLoggedInUser"></div>
        <div class="container block" ng-if="isLoggedInUser">
          <button avatar-badges="user" class="profile-thumb xlarge" ng-click="openOverlay('changeProfileImg')"><div class="profile-thumb-edit-overlay"></div></button>
        </div>
        <div class="container block" ng-if="isLoggedInUser">
          <button class=" edit banner profile-setting edit-banner" ng-click="openOverlay('changeProfileBg')" aria-label="Edit Banner"></button>
          <button class=" edit username profile-setting edit-slug" ng-click="openOverlay('changeSlug')" aria-label="Choose username"></button>
        </div>
        <div class="cover-content-btn spacer"></div>
      </div>
      <h1 class="cover-content-name h4" aria-label="{{user.name}} Profile">{{user.name}}</h1>
      <div class="cover-content-title" ng-if="user.user_title">{{user.user_title}}</div>
      <div class="cover-content-desc">
        @{{user.slug}} <span ng-if="isLoggedInUser">| Platform ID: {{currentUser.platform_id}}</span>
      </div>
      <div class="cover-content-social">
        <span ng-show="user.country.name"><span class=" icon-location"></span>{{user.country.name}}</span><span ng-show="user.country.name && user_role" > | </span>{{user_role}}<br>
        <a ng-if="user.social_network_urls.twitter" ng-href="https://twitter.com/{{user.social_network_urls.twitter}}" class="icon-twitter-material"></a>
        <a ng-if="user.social_network_urls.linked_in" ng-href="{{user.social_network_urls.linked_in | prependHttp}}" class="icon-linkedin-material"></a>
      </div>
      <community-follow-btn user="user" ng-if="user.current_user_permissions['follow'] && !isLoggedInUser"></community-follow-btn>
      <a ng-href="{{editProfileLink}}" class="mid edit profile edit-profile" ng-if="isLoggedInUser" aria-label="Edit profile"></a>
      <div class="cover-content-referral" ng-if="isLoggedInUser">
        Your Referral Link: <a ng-href="{{referralLink}}" title="Copy link to clipboard" clipboard data-clipboard-text="{{referralLink}}" ng-click="$event.preventDefault();"><i class="icon-docs"></i>{{referralLink}}</a>
      </div>
    </div>
    <div class="profile-cover-figures">
      <div class="container">
        <a class="points">{{user.points | number}}</a>
        <a ng-if="user.rank && !user.is_employee" class="urank"><span ng-bind="user.rank | number" ></span><small> / <span ng-bind="user.global_lowest_rank | number" ></span></small></a>
        <a ui-sref="followers({profileId: user.slug})" class="followers" >{{user.follow_stats.follower_count | number}}</a>
        <a ui-sref="following({profileId: user.slug})" class="following" >{{user.follow_stats.following_count | number}}</a>
      </div>
    </div>
  </div>
  <div class="container block">
    <div class="c12">
      <div class="profile-feeds">
        <ul class="nav nav-tabs">
          <li ng-class="{active: $state.includes('profile.about')}">
            <a ui-sref="profile.about({profileId: user.slug})">About Me</a>
          </li>
          <li ng-class="{active: $state.includes('profile.articles')}">
            <a ui-sref="profile.articles({profileId: user.slug})">Articles</a>
          </li>
          <li ng-class="{active: $state.includes('profile.status')}">
            <a ui-sref="profile.status({profileId: user.slug})">Status Updates</a>
          </li>
          <li ng-class="{active: $state.includes('profile.comments')}">
            <a ui-sref="profile.comments({profileId: user.slug})">Comments</a>
          </li>
          <li ng-class="{active: $state.includes('profile.mentions')}">
            <a ui-sref="profile.mentions({profileId: user.slug})">Mentions</a>
          </li>
          <li ng-class="{active: $state.includes('profile.topics')}">
            <a ui-sref="profile.topics({profileId: user.slug})">Topics</a>
          </li>
          <li ng-class="{active: $state.includes('profile.likes')}" ng-if="isLoggedInUser" >
            <a ui-sref="profile.likes({profileId: user.slug})">Likes</a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="ui-view"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="slide-up slide-up-move" id="quick-add">
  <p>Mention <strong>{{user.name}}</strong> on your status:</p>
  <div class="easy-write" ng-class="status">
    <div class="easy-write-content">
      <write-status mention="user" current-user="currentUser" popover-placement="top" ></write-status>
    </div>
    <div class="easy-write-status">
      <p>Status Successfully Posted!</p>
    </div>
  </div>
</section>
<div class="profile-overlay">
<dialog aria-labelledby="changeBgTitle" class="overlay overlay-scale" id="changeProfileBg">
  <h2 id="changeBgTitle" class="center">Change Background Image</h2>
  <form name="bannerForm"  ng-submit="saveChanges(profileData.banner, bannerForm.$valid, 'banner');openOverlay('changeProfileBg')">
    <upload-btn on-change="profileBannerChange(file)"></upload-btn>
    <div class="overlay-small-type terms">Profile banner images should be 600px wide and 360px tall. Accepted file types are .jpg, .jpeg, .gif, and .png. All file uploads must adhere to our <a href="/terms-and-conditions">Terms &amp; Conditions for Testers</a> and <a href="/utest-guidelines">uTest Guidelines</a>.</div>
    <input contenteditable="true" type="submit" class="btn btn-filled btn-yellow" value="Save Changes" />
  </form>
</dialog>
<dialog aria-labelledby="changeSlugTitle" class="overlay overlay-scale" id="changeSlug">
  <h2 id="changeSlugTitle" class="center">Choose Username</h2>
  <form name="slugForm" ng-submit="saveChanges(profileData.basic, slugForm.$valid, 'slug')">
    <div class="overlay-small-type terms">Usernames are required in order to be tagged by other users.</div>
    <div class="sign-up-form-input long">
      <input autofocus ng-pattern="/^[a-zA-Z0-9_\@\.-]+$/" validation-api="user_slug" type="text" ng-model="profileData.basic.slug" ng-model-options="{ debounce: 500 }"  id="slug" />
      <div class="sign-up-form-input-error">Username is already in use</div>
      <label for="slug" class="sign-up-form-input-label">Enter your desired username here</label>
    </div>
    <input contenteditable="true" type="submit" class="btn btn-filled btn-yellow" value="Save Changes" />
  </form>
</dialog>
<dialog aria-labelledby="changeProfileImgTitle" class="overlay overlay-scale" id="changeProfileImg">
  <h2 id="changeProfileImgTitle" class="center">Change Profile Image</h2>
  <form name="avatarForm" ng-submit="saveChanges(profileData.avatar, avatarForm.$valid, 'avatar');openOverlay('changeProfileImg')">
    <upload-btn on-change="avatarChange(file)"></upload-btn>
    <div class="overlay-small-type terms">Avatars should be square in shape and a maximum of 300px wide and 300px tall. Acceptable file types are .jpg, .jpeg, .gif, and .png. All file uploads must adhere to our <a href="/terms-and-conditions">Terms &amp; Conditions for Testers</a> and <a href="/utest-guidelines">uTest Guidelines</a>.</div>
    <div class="separate-line">OR</div>
    <div class="sign-up-form-input long" contenteditable="true">
      <input contenteditable="true" type="text" ng-model="profileData.avatar.gravatar" name="gravatar" id="gravatar" />
      <label for="gravatar" class="sign-up-form-input-label" contenteditable="true">Enter your Gravatar email address here</label>
    </div>
    <input contenteditable="true" type="submit" class="btn btn-filled btn-yellow" value="Save Changes" />
  </form>
</dialog>
</div>
`;
