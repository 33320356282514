class ResourcesCtrl {
  constructor($state) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = () => {
      if (ctrl.user) {
        $state.go('article');
      }
    };
  }
}

ResourcesCtrl.$inject = ['$state'];

export default ResourcesCtrl;
