export default `
<div class="navbar navbar-default navbar-fixed-top navbar-static-top ntux-nav-header" id="nav-bar-container">
  <nav-sidebar-toggle action="open"></nav-sidebar-toggle>
  <cookie-alert></cookie-alert>
  <alert-message current-user="$ctrl.currentUser"></alert-message>
  <div class="container-fluid ntux-nav-header-container">
    <div ng-show="!$ctrl.loadingUser">
      <div class="clearfix" ng-if="$ctrl.currentUser">
        <nav-header-search-field query="$ctrl.query" on-submit=$ctrl.search($event)></nav-header-search-field>
        <div class="ntux-nav-header-buttons">
          <nav-header-chat url="$ctrl.chatUrl"></nav-header-chat>
          <nav-header-notifications current-user="$ctrl.currentUser"></nav-header-notifications>
          <nav-header-help></nav-header-help>
        </div>
      </div>
      <div class="ut-navbar-easy-write guest ntux-nav-bar-header-unauth" ng-if="!$ctrl.currentUser">
        <span class="hidden-xs">Already a member of the uTest community?</span> <a href="/login?return_to={{$ctrl.$location.absUrl()}}" target="_self">Sign in</a> <span class="hidden-xs">Not a member?</span> <span class="visible-xs-inline">/</span> <a ui-sref="signup.personal">Sign Up For Free</a>
      </div>
    </div>
  </div>
  <app-identity-verification-banner
    ng-if="$ctrl.currentUser"
    [verification-status]="$ctrl.currentUser.identity_verification_status"
    [verification-url]="$ctrl.testerIdentityVerificationUrl"
  ></app-identity-verification-banner>
</div>
`;
