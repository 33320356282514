import LandingPageService from '../landing-pages/landing-page.service';
import LandingPageCtrl from '../landing-pages/landing-page.controller';
import landingPageTemplate from '../landing-pages/landing-page.html';
import StaticPageService from '../static-pages/static-page.service';
import StaticPageCtrl from '../static-pages/static-page.controller';

const StaticContentRouter = {
  route: (module) => {
    module
      .service('LandingPageService', LandingPageService)
      .controller('LandingPageCtrl', LandingPageCtrl)
      .service('StaticPageService', StaticPageService)
      .controller('StaticPageCtrl', StaticPageCtrl);

    module.config(['$stateProvider', ($stateProvider) => {
      $stateProvider
        .state('staticcontent', {
          url: '/:pageSlug',
          parent: 'root',
          template: '<main id="mainContent" role="main"><div ng-bind-html="page.content | trustAsHtml"></div></main>',
          controller: 'StaticPageCtrl',
          resolve: {
            page: ['StaticPageService', '$transition$', '$state', '$rootScope',
              (StaticPageService, $transition$, $state) => {
                const spPromise = StaticPageService.get($transition$.params().pageSlug);
                spPromise.catch(() => {
                  // If a static page doesn't exist, try the landing page
                  $state.go('staticcontentLandingPage', { pageSlug: $transition$.params().pageSlug }, { location: false });
                });

                return spPromise;
              }],
            title: ['page', page => `uTest - ${page.data.title}`],
          },
        })
        .state('staticcontentLandingPage', {
          url: '/:pageSlug',
          parent: 'root',
          template: landingPageTemplate,
          controller: 'LandingPageCtrl',
          data: {
            routeClasses: 'lp',
          },
          resolve: {
            page: ['LandingPageService', 'ProgressBarService', '$transition$', '$state', '$window',
              (LandingPageService, ProgressBarService, $transition$, $state, $window) => {
                const lpPromise = LandingPageService.get($transition$.params().pageSlug);

                lpPromise.then((result) => {
                  /* eslint-disable */
                  result.data.redirect && ($window.location = result.data.title);
                  /* eslint-enable */
                });

                lpPromise.catch(() => {
                  ProgressBarService.done();

                  $state.go('fourohfour', null, { location: false });
                });

                return lpPromise;
              }],
            title: ['page', page => `uTest - ${page.data.title}`],
          },
        });
    }]);
  },
};

export default StaticContentRouter;
