/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { Meta } from '@angular/platform-browser';
import { AutocompleteComponent } from '@ng-falcon/controls/autocomplete/autocomplete.component';
import { OptionsContainerComponent } from '@ng-falcon/controls/option/options-container/options-container.component';
import { OptionComponent } from '@ng-falcon/controls/option/option/option.component';
import { UnauthenticatedHomeComponent } from './home/unauthenticated-home/unauthenticated-home.component';
import { UtEditorComponent } from './common/ut-editor/ut-editor.component';
import { CommunityNavComponent } from './common/community/nav/community-nav.component';
import { CommunityFeedItemLikeBtnComponent } from './common/community/likes/community-feed-item-like-btn/community-feed-item-like-btn.component';
import { CommunityFeedItemLikeCountComponent } from './common/community/likes/community-feed-item-like-count/community-feed-item-like-count.component';
import { TagBtnComponent } from './common/components/tag-btn/tag-btn.component';
import { ArticleFeedItemComponent } from './article/article-feed-item/article-feed-item.component';
import { CommentBtnComponent } from './shared/comment-btn/comment-btn.component';
import { IdentityVerificationBannerComponent } from './common/components/identity-verification-banner/identity-verification-banner.component';

declare const angular: any;

angular.module('uTestV2')
  .directive(
    'utUnauthenticatedHome',
    downgradeComponent({ component: UnauthenticatedHomeComponent }),
  )
  .directive(
    'utEditor',
    downgradeComponent({ component: UtEditorComponent }),
  )
  .directive(
    'ngfAutocomplete',
    downgradeComponent({ component: AutocompleteComponent }),
  )
  .directive(
    'ngfOptionsContainer',
    downgradeComponent({ component: OptionsContainerComponent }),
  )
  .directive(
    'ngfOption',
    downgradeComponent({ component: OptionComponent }),
  )
  .directive(
    'communityNav',
    downgradeComponent({ component: CommunityNavComponent }),
  )
  .directive(
    'utCommunityFeedItemLikeBtn',
    downgradeComponent({ component: CommunityFeedItemLikeBtnComponent }),
  )
  .directive(
    'utCommunityFeedItemLikeCount',
    downgradeComponent({ component: CommunityFeedItemLikeCountComponent }),
  )
  .directive(
    'utTagBtn',
    downgradeComponent({ component: TagBtnComponent }),
  )
  .directive(
    'utArticleFeedItem',
    downgradeComponent({ component: ArticleFeedItemComponent }),
  )
  .directive(
    'utCommentBtn',
    downgradeComponent({ component: CommentBtnComponent }),
  )
  .directive(
    'appIdentityVerificationBanner',
    downgradeComponent({ component: IdentityVerificationBannerComponent }),
  )
  .factory(
    'Meta',
    downgradeInjectable(Meta as any),
  );
