import template from './single-resource.component.html';
import controller from './single-resource.controller';

const singleResource = {
  bindings: {
    article: '<',
    user: '<',
  },
  template,
  controller,
};

export default singleResource;
