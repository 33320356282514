class SingleResourceCtrl {
  constructor($sce: ng.ISCEService, $filter, $state, MetaService) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = () => {
      ctrl.updateMetaService();
      ctrl.article.content = $sce.trustAsHtml($filter('uMarkdown')(ctrl.article.content));
      if (ctrl.user) {
        $state.go('singlearticle', { articleId: ctrl.article.slug });
      }
    };

    ctrl.updateMetaService = () => {
      if (ctrl.article.canonical) {
        MetaService.set('canonical', ctrl.article.canonical);
      }
    };
  }
}

SingleResourceCtrl.$inject = ['$sce', '$filter', '$state', 'MetaService'];

export default SingleResourceCtrl;
