import angular from 'angular';
import { APPLICATION_SIZE_MODES } from '../../constants';

class SingleProjectCtrl {
  constructor(
    $scope: ng.IScope,
    $state,
    $window,
    ProjectService,
    UserService,
    $location,
    ConfigService,
    featureFlags,
  ) {
    const ctrl: ng.IController = this;
    ctrl.$onInit = () => {
      ConfigService.config().then((res) => {
        ctrl.utestUrl = res.data.utest_url;
      });
      ctrl.projectData = ctrl.project.data;
      ctrl.service = ProjectService;
      ctrl.$location = $location;
      ctrl.headerData = {
        title: 'Projects Board',
        description: 'Please know, the Projects Board shows only a small fraction of the paid project opportunities uTest has to offer! Increase your chances at being matched with paid project opportunities by keeping your Tester Profile complete and up-to-date! <br> <br>\n'
        + '        We need your help! The projects listed below are ones which we were unable to automatically identify testers for due to unique requirements. Read through the project details carefully and apply for any project you are a match for!',
        unauthDescription: featureFlags.isOn('UNAUTHENTICATED_UTEST_REDESIGN_2025')
          ? 'Whether you’re an everyday technology user or a subject-matter expert, you can start improving digital products as a paid tester with uTest. Browse opportunities below and sign up to receive even more in your inbox.'
          : 'Please view a small fraction of the available paid project opportunities uTest has to offer! Sign up today and fill out your tester profile to receive more projects.',
        coverBg: {
          small: 'assets/images/hero/projects-bg-xs.jpg',
          large: 'assets/images/hero/projects-bg.jpg',
        },
        unauthCoverBg: {
          wide: 'assets/images/hero/unauth-background.svg',
          medium: 'assets/images/hero/unauth-background-s.svg',
          small: 'assets/images/hero/unauth-background-xs.svg',
        },
      };

      UserService.me().then((response) => {
        if (response !== null) {
          ctrl.currentUser = response.data;
        } else {
          ctrl.currentUser = null;
        }
      });

      ConfigService.config().then((res) => {
        ctrl.globalConfig = res.data;
      });

      ctrl.fullUrl = $state.href('singleproject', { projectId: ctrl.projectData.id }, { absolute: true });
      ctrl.calculateBgStyle();
      angular.element($window).on('resize', () => {
        ctrl.calculateBgStyle();
      });
    };

    ctrl.applyForProject = function (project) {
      if (!project.user_has_applied) {
        ProjectService.apply(project.id)
          .then((_response) => {
            project.user_has_applied = true;
          });
      }

      let openUrl = project.survey_link;
      if (openUrl && ctrl.currentUser) {
        openUrl = openUrl.replace('$id$', encodeURIComponent(ctrl.currentUser.platform_id));
        openUrl = openUrl.replace('$email$', encodeURIComponent(ctrl.currentUser.email));
        openUrl = openUrl.replace('$name$', encodeURIComponent(ctrl.currentUser.name));
      }

      const wnd = $window.open(openUrl, '_blank');
      wnd.opener = null;
    };
    ctrl.calculateBgStyle = () => {
      let resUrl = ctrl.headerData.unauthCoverBg.small;
      if ($window.innerWidth >= APPLICATION_SIZE_MODES.WIDE) {
        resUrl = ctrl.headerData.unauthCoverBg.wide;
      } else if ($window.innerWidth >= APPLICATION_SIZE_MODES.MEDIUM) {
        resUrl = ctrl.headerData.unauthCoverBg.medium;
      }
      ctrl.unauthHeaderBg = resUrl;
    };
  }
}

SingleProjectCtrl.$inject = [
  '$scope',
  '$state',
  '$window',
  'ProjectService',
  'UserService',
  '$location',
  'ConfigService',
  'featureFlags',
];

export default SingleProjectCtrl;
