import appTemplate from '../layouts/app.html';
import HomeFeedService from '../components/home/home-feed.service';
import UnauthenticatedAssetsService from '../components/services/unauthenticated-assets.service';
import CommunityManagersService from '../components/services/community-managers.service';
import community from '../components/community/community.component';
import home from '../components/home/home.component';
import resources from '../components/resource/resources.component';
import singleResource from '../components/resource/single-resource.component';
import UserResolver from '../authentication/user-resolver';
import UserAuthenticator from '../authentication/user-authenticator';
import FeatureToggleResolver from '../feature-toggles/feature-toggle-resolver';

const HomeRouter = {
  route: (module) => {
    module
      .service('HomeFeedService', HomeFeedService)
      .service('UnauthenticatedAssetsService', UnauthenticatedAssetsService)
      .service('CommunityManagersService', CommunityManagersService)
      .component('home', home)
      .component('community', community)
      .component('resources', resources)
      .component('singleresource', singleResource)
      .config(['$stateProvider', ($stateProvider) => {
        $stateProvider
          .state('root', {
            controller: ['$state', '$scope', '$rootScope', 'featureFlags', 'user', ($state, $scope, $rootScope, featureFlags, user) => {
              $scope.$state = $state;
              $scope.featureFlags = featureFlags;
              if (user) {
                $scope.currentUser = user;
                $rootScope.showNtuxNavigation = true;
                $rootScope.scrollBehaviorClass = 'overflow-hidden';
              } else {
                $rootScope.showNtuxNavigation = false;
                $rootScope.scrollBehaviorClass = 'overflow-scroll';
              }
            }],
            url: '',
            abstract: true,
            template: appTemplate,
            resolve: {
              features: FeatureToggleResolver,
              user: UserResolver,
            },
          })
          .state('home', {
            url: '/',
            parent: 'root',
            component: 'home',
            resolve: {
              user: UserResolver,
              title: [() => 'uTest - The Professional Network for Testers'],
              staticPage: ['StaticPageService', 'featureFlags', function (StaticPageService, featureFlags) {
                if (featureFlags.isOn('UNAUTHENTICATED_UTEST_REDESIGN_2025')) {
                  return StaticPageService.get('home-5tat1c').then((response) => {
                    if (response.data) {
                      return response.data;
                    }
                  }).catch(() => false);
                }
                return false;
              }],
            },
          })
          .state('community', {
            url: '/community',
            component: 'community',
            parent: 'root',
            resolve: {
              user: UserAuthenticator,
              title: [() => 'uTest Community Feed'],
            },
          })
          .state('resources', {
            url: '/resources',
            parent: 'root',
            component: 'resources',
            resolve: {
              staticPage: ['StaticPageService', 'featureFlags', '$state', function (StaticPageService, featureFlags, $state) {
                if (featureFlags.isOn('UNAUTHENTICATED_UTEST_REDESIGN_2025')) {
                  return StaticPageService.get('resources-5tat1c').then((response) => {
                    if (response.data) {
                      return response.data;
                    }
                  }).catch(() => $state.go('fourohfour', null, { location: false }));
                }
                $state.go('fourohfour', null, { location: false });
              }],
              title: [() => 'uTest Resources'],
            },
          })
          .state('singleresource', {
            parent: 'root',
            url: '/resources/:articleId',
            component: 'singleresource',
            resolve: {
              article: ['ArticleService', '$transition$', function (ArticleService, $transition$) {
                return ArticleService.find($transition$.params().articleId).then(res => res.data);
              }],
              title: ['article', article => `uTest Resources - ${article.title}`],
            },
          });
      }]);
  },
};

export default HomeRouter;
