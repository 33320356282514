class UnauthenticatedFooterNewCtrl {
  constructor() {
    // @ts-ignore
    this.$onInit = () => {
      const ctrl = this as any;
      ctrl.year = new Date().getFullYear();
    };
  }
}

export default UnauthenticatedFooterNewCtrl;
