export default `
<div class="unauthenticated-container">
  <div feature-flag="UNAUTHENTICATED_UTEST_REDESIGN_2025" feature-flag-hide>
    <unauthenticated-header></unauthenticated-header>

    <div auto-padding="nav-bar-container" auto-padding-event="adjust-nav-padding" id="mainUiView" class='main-ui-view unauthenticated-view' scrolly scroll-target='#navBar' autoscroll="true" ng-style="!$ctrl.showHeaderFooter && {'padding-top':'0px'}">
      <ui-view></ui-view>
    </div>
  </div>

  <div feature-flag="UNAUTHENTICATED_UTEST_REDESIGN_2025">
    <unauthenticated-header-new></unauthenticated-header-new>

    <div id="mainUiView" class='unauthenticated-view' scrolly scroll-target='#navBar' autoscroll="true">
      <ui-view></ui-view>
    </div>
  </div>
  <unauthenticated-footer feature-flag="UNAUTHENTICATED_UTEST_REDESIGN_2025" feature-flag-hide></unauthenticated-footer>
  <unauthenticated-footer-new feature-flag="UNAUTHENTICATED_UTEST_REDESIGN_2025"></unauthenticated-footer-new>
</div>
`;
