import template from './unauthenticated-header-nav-item-new.component.html';
import controller from './unauthenticated-header-nav-item-new.controller';

const unauthenticatedHeaderNavItemNew = {
  bindings: {
    name: '@',
    route: '@',
    highlight: '@',
  },
  template,
  controller,
};

export default unauthenticatedHeaderNavItemNew;
