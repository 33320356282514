export default `
<header class="unauthenticated-nav-bar-new" role="banner">
  <div class="unauthenticated-nav-bar-new__container">
    <div class="unauthenticated-nav-bar-new__logo">
      <a ui-sref="home"><img src="assets/images/uTestLogoNavDark.svg" alt="uTest home page"></a>
    </div>
    <nav class="unauthenticated-nav-bar-new__links navbar-right unauthenticated-nav-bar-new--hidden-tablet unauthenticated-nav-bar-new--hidden-mobile" aria-label="Main Navigation">
      <ul class="nav navbar-nav">
        <li>
          <unauthenticated-header-nav-item-new name="Why uTest" route="why-utest" highlight="why-utest"></unauthenticated-header-nav-item-new>
        </li>
        <li>
          <unauthenticated-header-nav-item-new name="About Us" route="about-us" highlight="about-us"></unauthenticated-header-nav-item-new>
        </li>
        <li>
          <unauthenticated-header-nav-item-new name="Projects" route="projects" highlight="project,singleproject,projectlist"></unauthenticated-header-nav-item-new>
        </li>
        <li>
          <unauthenticated-header-nav-item-new name="Resources" route="resources" highlight="resources,singleresource"></unauthenticated-header-nav-item-new>
        </li>
      </ul>
    </nav>
    <div class="unauthenticated-nav-bar-new__right-group">
      <ul class="nav unauthenticated-nav-bar-new__authentication unauthenticated-nav-bar-new--hidden-mobile">
        <li>
          <a class="unauthenticated-nav-bar-new__log-in" href="/login?return_to={{$ctrl.$location.absUrl()}}" target="_self">Log In</a>
        </li>
        <li>
          <a class="unauthenticated-nav-bar-new__sign-up" ui-sref="signup.personal">Join Now</a>
        </li>
      </ul>
      <div class="unauthenticated-nav-bar-new__dropdown-toggle unauthenticated-nav-bar-new--hidden-desktop-sm unauthenticated-nav-bar-new--hidden-desktop-md" >
        <div>
          <button id="menu-button" ng-class="$ctrl.isOpen ? 'unauthenticated-nav-bar-new__dropdown-toggle--close' : 'unauthenticated-nav-bar-new__dropdown-toggle--open'" aria-expanded="{{$ctrl.isOpen}}" aria-controls="unauthenticated-dropdown-nav" ng-click="$ctrl.toggleMenu()" aria-label="Toggle Hamburger Menu">
          </button>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="unauthenticated-nav-bar-new__dropdown-container unauthenticated-nav-bar-new--hidden-desktop-sm unauthenticated-nav-bar-new--hidden-desktop-md">
  <ul ng-show="$ctrl.isOpen" id="unauthenticated-dropdown-nav"
        class="unauthenticated-nav-bar-new__dropdown-menu" aria-label="Dropdown Navigation Menu">
    <li>
      <a class="unauthenticated-nav-bar-new__dropdown-menu-link" href="why-utest" ng-click="$ctrl.closeMenu()" aria-label="Why uTest">
        Why uTest
        <img src="https://s3.us-east-1.amazonaws.com/utest.com/assets/resources/img/menu-arrow.svg" alt="decorative">
      </a>
    </li>
    <li>
      <a class="unauthenticated-nav-bar-new__dropdown-menu-link" href="about-us" ng-click="$ctrl.closeMenu()" aria-label="About us">
        About Us
        <img src="https://s3.us-east-1.amazonaws.com/utest.com/assets/resources/img/menu-arrow.svg" alt="decorative">
      </a>
    </li>
    <li>
      <a class="unauthenticated-nav-bar-new__dropdown-menu-link" href="projects" ng-click="$ctrl.closeMenu()" aria-label="Projects">
        Projects
        <img src="https://s3.us-east-1.amazonaws.com/utest.com/assets/resources/img/menu-arrow.svg" alt="decorative">
      </a>
    </li>
    <li>
      <a class="unauthenticated-nav-bar-new__dropdown-menu-link" href="resources" ng-click="$ctrl.closeMenu()" aria-label="Resources">
        Resources
        <img src="https://s3.us-east-1.amazonaws.com/utest.com/assets/resources/img/menu-arrow.svg" alt="decorative">
      </a>
    </li>
    <li>
      <a class="unauthenticated-nav-bar-new__dropdown-menu-log-in unauthenticated-nav-bar-new--hidden-tablet" href="/login?return_to={{$ctrl.$location.absUrl()}}" target="_self">
        Log In
      </a>
    </li>
    <li>
      <a class="unauthenticated-nav-bar-new__dropdown-menu-sign-up unauthenticated-nav-bar-new--hidden-tablet" ui-sref="signup.personal">
        Join Now
      </a>
    </li>
  </ul>
</div>

`;
