import _ from 'lodash';
import angular from 'angular';
import * as Ladda from 'ladda';
import Bowser from 'bowser';
import {
  EMAIL_REGEX, CAPTCHA_UTEST, CAPTCHA_NONE, NAME_REGEX,
  SIGN_UP_DROPDOWN_LABELS_HASH,
} from '../constants';
import termsModalTemplate from '../components/modal/terms-modal.html';

class SignUpCtrl {
  constructor(
    $scope, UserService, $state, StaticValuesService, DeviceDetector, // eslint-disable-line function-paren-newline
    LanguageCodeFactory, $q, $document, $timeout, vcRecaptchaService, // eslint-disable-line function-paren-newline
    $rootScope, $transitions, $location, leftPanelBg, globalConfig, // eslint-disable-line function-paren-newline
    AlertService, ServerMessages, uiGmapGoogleMapApi, $uibModal, // eslint-disable-line function-paren-newline
    StaticPageService, AccessibleFormService, $window, $element, GeoService, // eslint-disable-line function-paren-newline
  ) {
    let spinnerBtn;
    $rootScope.leftPanelBg = leftPanelBg;
    $scope.recaptchaKey = globalConfig.recaptcha_client_key;
    $scope.isSignupInProgress = false;
    $scope.isGoogleMapsFocused = false;
    $scope.forms = {};
    const webDeviceObj = {
      osId: '',
      osVersionId: '',
      osLanguageId: '',
      browsers: [],
    };
    const mobileDeviceObj = {
      handsetMakerId: '',
      handsetModelId: '',
      handsetOSId: '',
    };

    $scope.registrationData = {
      languages: [],
      birthDate: {},
      countries: [],
      webDevice: angular.copy(webDeviceObj),
      mobileDevice: angular.copy(mobileDeviceObj),
      httpReferrer: document.referrer,
    };
    const autoDetectWebObj = {
      osId: false,
      osVersionId: false,
      osLanguageId: false,
    };
    const autoDetectMobileObj = {
      handsetMakerId: false,
      handsetOSId: false,
    };

    const fixUsabilityForCombos = () => {
      $timeout(() => {
        const container = $element.find('.ui-select-container');
        if (container.length > 0) {
          _.forEach(container, (elem) => {
            const txt = angular.element(elem).find('.ui-select-match').attr('placeholder');
            angular.element(elem).find('.ui-select-match:input').attr('aria-label', txt);
            angular.element(elem).find('.ui-select-toggle').attr('aria-label', txt);
            angular.element(elem).find('.ui-select-search').attr('aria-label', txt);
          });
        }
      }, 250);
    };

    const ctrl: ng.IController = this;

    const waitForFormAndSubmitIfValid = () => {
      $timeout(() => {
        const formElement = angular.element(document.querySelector('[name="userForm"]'));
        const form = formElement.controller('form');
        if (form && form.$valid) {
          $scope.validateBasicInfoStep(form);
        }
      });
    };

    ctrl.$onInit = () => {
      fixUsabilityForCombos();
      prefillFieldsFromQueryParams($scope.registrationData);
      waitForFormAndSubmitIfValid();
    };

    $scope.geoOptions = [];
    $scope.handleLocationSearch = (query) => {
      $scope.cityFieldEdited();
      GeoService.search(query).then(res => { $scope.geoOptions = res.data; });
    };
    $scope.displayGeoLabel = (place) => $scope.selectedCity || place.description;

    $scope.autoDetectDevice = {
      webDevice: angular.copy(autoDetectWebObj),
      mobileDevice: angular.copy(autoDetectMobileObj),
    };

    $scope.autoDetectAddress = {
      city: false,
      state: false,
      country: false,
      zip: false,
    };

    $scope.email_pattern = new RegExp(EMAIL_REGEX);
    $scope.name_pattern = new RegExp(NAME_REGEX);

    // check if user is already signed in
    let user = null;
    UserService.me().then((res) => {
      if (res !== null) {
        user = res.data;
      }
    });

    function addCampaignFields(registrationData) {
      const queryParams = $location.search();

      const utmData: any = {};

      if (queryParams.utm_campaign) {
        utmData.utmCampaign = queryParams.utm_campaign;
      }

      if (queryParams.utm_source) {
        utmData.utmSource = queryParams.utm_source;
      }

      if (queryParams.utm_medium) {
        utmData.utmMedium = queryParams.utm_medium;
      }

      if (queryParams.utm_content) {
        utmData.utmContent = queryParams.utm_content;
      }

      if (queryParams.utm_term) {
        utmData.utmTerm = queryParams.utm_term;
      }

      // eslint-disable-next-line max-len
      if (utmData.utmCampaign || utmData.utmSource || utmData.utmMedium || utmData.utmContent || utmData.utmTerm) {
        _.set(registrationData, 'campaigns[0]', utmData);
      }
    }

    function addReferralTesterId(registrationData) {
      const queryParams = $location.search();

      if (queryParams.referral_tester_id) {
        registrationData.referralTesterId = queryParams.referral_tester_id;
      }
    }

    // example query params: ?first-name=Tester&last-name=One&email=tester1@gmail.com&month=1&day=3&year=2003
    function prefillFieldsFromQueryParams(registrationData) {
      const queryParams = $location.search();
      if (queryParams['first-name']) {
        registrationData.firstName = queryParams['first-name'];
      }
      if (queryParams['last-name']) {
        registrationData.lastName = queryParams['last-name'];
      }
      if (queryParams.email) {
        registrationData.email = queryParams.email;
      }
      if (queryParams.month) {
        registrationData.birthDate.month = queryParams.month;
        $scope.selectMonth();
      }
      if (queryParams.day) {
        registrationData.birthDate.day = queryParams.day;
        $scope.selectDay();
      }
      if (queryParams.year) {
        registrationData.birthDate.year = queryParams.year;
        $scope.selectYear();
      }
    }

    addReferralTesterId($scope.registrationData);

    addCampaignFields($scope.registrationData);

    function isValidForm(form) {
      form.$$submitted = true;
      return !Object.keys(form.$error).length;
    }

    function isEmptyKeyInObject(obj) {
      if (Object.keys(obj).length > 0) {
        return !Object.keys(obj).every(key => obj[key] !== '' && obj[key] !== null && obj[key] !== undefined);
      }
      return true;
    }

    function searchItemInArray(arr, text) {
      const searchedItem = _.find(arr, device => device.name.toLowerCase().indexOf(text) !== -1);
      return (searchedItem || null);
    }

    $scope.shouldShowProgressBar = () => {
      if (!$rootScope.customRegistration) return true;
      return $rootScope.customRegistration.registration_type === 'full';
    };

    $scope.firstStep = () => {
      if ($rootScope.customRegistration && $rootScope.customRegistration.registration_type === 'lite') {
        $scope.navigateToStep('lite');
      } else {
        $scope.navigateToStep('personal');
      }
    };

    $scope.years = StaticValuesService.years();
    $scope.months = StaticValuesService.months();
    $scope.days = StaticValuesService.days();

    $scope.toNumber = function (value: string) {
      return parseInt(value);
    };

    $scope.selectDay = function () {
      const { day, month, year } = $scope.registrationData.birthDate;
      const dayAsNumber = $scope.toNumber(day);
      $scope.days = StaticValuesService.days(month, year);
      // if selected date does not exist in selected year or month then reset the date
      if ($scope.days.indexOf(dayAsNumber) === -1) {
        $scope.registrationData.birthDate.day = undefined;
      }
    };

    $scope.selectMonth = function () {
      $scope.selectDay();

      const { month, year } = $scope.registrationData.birthDate;
      $scope.months = StaticValuesService.months(year);

      // if selected month is not valid in selected year then reset the month
      if (month && Object.keys($scope.months).indexOf((month - 1).toString()) === -1) {
        $scope.registrationData.birthDate.month = '';
      }
    };

    $scope.selectYear = function () {
      $scope.selectDay();
      $scope.selectMonth();
      const { year } = $scope.registrationData.birthDate;
      const yearAsNumber = $scope.toNumber(year);
      // if selected year is not valid then reset the year
      if (year && $scope.years.indexOf(yearAsNumber) === -1) {
        $scope.registrationData.birthDate.year = '';
      }
    };

    // Step 1 methods, capturing personal info
    $scope.validateBasicInfoStep = function (form) {
      if (isValidForm(form)) {
        $scope.navigateToStep('address').then(() => {
          setAddress();
          fixUsabilityForCombos();
          setTimeout(() => {
            $scope.initializeUiSelectDropdownAria();
          }, 100);
        });
      } else { AccessibleFormService.focusFirstError(form, $scope); }
    };

    // Step 2 methods, adding location and map
    const locationCtrl: any = {};

    locationCtrl.componentForm = {
      city: 'long_name',
      administrative_area_level_1: 'long_name',
      country: 'short_name',
      postal_code: 'long_name',
    };

    $scope.selectedCity = null;

    $scope.cityFieldEdited = function () {
      $scope.autoDetectAddress.city = false;
    };

    $scope.validateAddressInfoStep = function (form) {
      if (isValidForm(form)) {
        $scope.navigateToStep('devices').then(() => {
          if ($scope.isMobile) {
            if (!$scope.registrationData.mobileDevice.handsetMakerId) {
              $timeout(() => {
                $rootScope.$broadcast('handsetMakerIdFocus');
              });
            }
          } else if (!$scope.registrationData.webDevice.osId) {
            $timeout(() => {
              $rootScope.$broadcast('osIdFocus');
            });
          }
          fixUsabilityForCombos();
        });
        setTimeout(() => {
          $scope.initializeUiSelectDropdownAria();
          $scope.fixDropdownFocusOrderAccessibility();
        }, 100);
      } else {
        AccessibleFormService.focusFirstError(form, $scope);
      }
    };

    $scope.selectCountry = function () {
      delete $scope.registrationData.stateId;
      $scope.states = $scope.statesByCountryId[$scope.registrationData.countryId];
      $scope.geocodeAddress();
      $scope.autoDetectAddress.country = false;
      $scope.autoDetectAddress.state = false;
    };

    $scope.isGoogleMapsAvailable = function () {
      return _.isObject(window.google);
    };

    function setCityFocus() {
      if (!$scope.registrationData.city) {
        $('#city').focus();
      }
    }

    function setAddress() {
      if (address().length) {
        $scope.geocodeAddress(true);
        setCityFocus();
      } else {
        geolocate();
      }
      initAutocomplete();
    }

    $scope.geocodeAddress = function (load = false) {
      if (!$scope.isGoogleMapsAvailable()) {
        return;
      }

      GeoService.geocode(address()).then(({ data: result }) => {
        $scope.registrationData.latitude = result.geometry.location.lat;
        $scope.registrationData.longitude = result.geometry.location.lng;

        if (load) {
          locationCtrl.map = new google.maps.Map(
            document.getElementById('map'),
            {
              center: result.geometry.location,
              zoom: 10,
              draggable: false,
            },
          );
          locationCtrl.marker = new google.maps.Marker({ map: locationCtrl.map, position: result.geometry.location });
          locationCtrl.geocoder = new google.maps.Geocoder();
        } else {
          locationCtrl.map.setCenter(result.geometry.location);
          locationCtrl.map.setZoom(10);
          locationCtrl.marker.setPosition(result.geometry.location);
          locationCtrl.marker.setVisible(true);
        }
      });
    };

    function mapCountry(countryCode) {
      const selectedCountry = _.find($scope.countries, country => country.code === countryCode);
      if (selectedCountry) {
        $scope.registrationData.countryId = `${selectedCountry.id}`; /* loaded countries have `id: number`, but select compares by string; */
        $scope.states = $scope.statesByCountryId[selectedCountry.id];
        $scope.registrationData.stateId = $scope.states ? $scope.registrationData.stateId : null;
        if (!$scope.registrationData.stateId) {
          delete $scope.registrationData.stateId;
        }
      }
    }

    function mapState(stateCode) {
      if ($scope.states) {
        const selectedState = _.find($scope.states, state => state.name === stateCode);
        if (selectedState) {
          $scope.registrationData.stateId = `${selectedState.id}`; /* loaded states have `id: number`, but select compares by string; */
        }
      }
    }

    function address() {
      const address = [];

      if ($scope.registrationData.city) {
        address.push($scope.registrationData.city);
      }
      if ($scope.states && $scope.registrationData.stateId) {
        const selectedState = _.find($scope.states, state => state.id === $scope.registrationData.stateId);
        if (selectedState) {
          address.push(selectedState.name);
        }
      }
      if ($scope.registrationData.countryId) {
        const selectedCountry = _.find($scope.countries, country => country.id === $scope.registrationData.countryId);
        if (selectedCountry) {
          address.push(selectedCountry.name);
        }
      }
      return address.join(', ');
    }

    function initAutocomplete() {
      if (!$scope.isGoogleMapsAvailable()) {
        return;
      }
      locationCtrl.cityAutocomplete = new google.maps.places.Autocomplete(
        document.getElementById('city'),
        { types: ['(cities)'] },
      );

      locationCtrl.cityAutocomplete.setFields([
        'address_components',
        'geometry',
      ]);

      locationCtrl.cityAutocomplete.addListener('place_changed', fillInAddress);
    }

    function fillInAddress() {
      locationCtrl.place = locationCtrl.cityAutocomplete.getPlace();

      const cityComponent = _.find(locationCtrl.place.address_components, component => _.includes(component.types, 'locality') || _.includes(component.types, 'administrative_area_level_3'));

      const countryComponent = _.find(locationCtrl.place.address_components, component => _.includes(component.types, 'country'));
      const zipComponent = _.find(locationCtrl.place.address_components, component => _.includes(component.types, 'postal_code'));
      const stateComponent = _.find(locationCtrl.place.address_components, component => _.includes(component.types, 'administrative_area_level_1'));

      $scope.selectedCity = cityComponent ? cityComponent[locationCtrl.componentForm.city] : null;
      $scope.registrationData.city = $scope.selectedCity;
      $scope.autoDetectAddress.city = false;
      $scope.registrationData.zip = zipComponent ? zipComponent[locationCtrl.componentForm.postal_code] : null;
      $scope.autoDetectAddress.zip = false;

      $scope.forms.userForm.city.$rollbackViewValue();

      if (countryComponent) {
        $scope.autoDetectAddress.country = false;
        mapCountry(countryComponent[locationCtrl.componentForm.country]);
      }
      if (stateComponent) {
        $scope.autoDetectAddress.state = false;
        mapState(stateComponent[locationCtrl.componentForm.administrative_area_level_1]);
      }

      if (locationCtrl.map) {
        if (locationCtrl.place.geometry.viewport) {
          locationCtrl.map.fitBounds(locationCtrl.place.geometry.viewport);
        } else {
          locationCtrl.map.setCenter(locationCtrl.place.geometry.location);
          locationCtrl.map.setZoom(10);
        }

        locationCtrl.marker.setPosition(locationCtrl.place.geometry.location);
        locationCtrl.marker.setVisible(true);
      }

      $scope.$apply();
    }

    $scope.fillInAddressFromGeo = async (place) => {
      if (!place) {
        $scope.selectedCity = null;
        $scope.registrationData.city = $scope.selectedCity;
        $scope.autoDetectAddress.city = false;
        return;
      }

      const placeDetails = await GeoService.details(place.place_id);
      locationCtrl.place = placeDetails.data;

      const cityComponent = _.find(locationCtrl.place.address_components, component => _.includes(component.types, 'locality') || _.includes(component.types, 'administrative_area_level_3'));

      const countryComponent = _.find(locationCtrl.place.address_components, component => _.includes(component.types, 'country'));
      const zipComponent = _.find(locationCtrl.place.address_components, component => _.includes(component.types, 'postal_code'));
      const stateComponent = _.find(locationCtrl.place.address_components, component => _.includes(component.types, 'administrative_area_level_1'));

      $scope.selectedCity = cityComponent ? cityComponent[locationCtrl.componentForm.city] : null;
      $scope.registrationData.city = $scope.selectedCity;
      $scope.autoDetectAddress.city = false;
      $scope.registrationData.zip = zipComponent ? zipComponent[locationCtrl.componentForm.postal_code] : null;
      $scope.autoDetectAddress.zip = false;
      $scope.forms.userForm.city.$rollbackViewValue();
      if (countryComponent) {
        $scope.autoDetectAddress.country = false;
        mapCountry(countryComponent[locationCtrl.componentForm.country]);
      }
      if (stateComponent) {
        $scope.autoDetectAddress.state = false;
        mapState(stateComponent[locationCtrl.componentForm.administrative_area_level_1]);
      }

      if (locationCtrl.map) {
        if (locationCtrl.place.geometry.viewport) {
          const { LatLngBounds } = await google.maps.importLibrary('core');
          const { viewport } = locationCtrl.place.geometry;
          const bound = new LatLngBounds(viewport.southwest, viewport.northeast);

          locationCtrl.map.fitBounds(bound);
        } else {
          locationCtrl.map.setCenter(locationCtrl.place.geometry.location);
          locationCtrl.map.setZoom(10);
        }

        locationCtrl.marker.setPosition(locationCtrl.place.geometry.location);
        locationCtrl.marker.setVisible(true);
      }

      $scope.$apply();
    };

    function geolocate() {
      if (!$scope.isGoogleMapsAvailable()) {
        return;
      }
      StaticValuesService.geolocate().then((geolocate) => {
        const latLang = geolocate.data.location;

        locationCtrl.map = new google.maps.Map(
          document.getElementById('map'),
          {
            center: latLang,
            zoom: 10,
            draggable: false,
          },
        );

        locationCtrl.marker = new google.maps.Marker({ map: locationCtrl.map, position: latLang });
        locationCtrl.geocoder = new google.maps.Geocoder();

        locationCtrl.geocoder.geocode({ location: latLang }, (results, status) => {
          if (status === 'OK') {
            const address: any = {};
            results[0].address_components.forEach((el) => {
              if (el.types[0] === 'administrative_area_level_1') {
                address[el.types[0]] = el.long_name;
              } else {
                address[el.types[0]] = el.short_name;
              }
            });

            if (!_.isEmpty(address.locality)) {
              $scope.selectedCity = address.locality;
              $scope.registrationData.city = $scope.selectedCity;
              $scope.registrationData.latitude = results[0].geometry.location.lat();
              $scope.registrationData.longitude = results[0].geometry.location.lng();
              $scope.autoDetectAddress.city = true;
            }

            if (!_.isEmpty(address.postal_code)) {
              $scope.registrationData.zip = address.postal_code;
              $scope.autoDetectAddress.zip = true;
            }

            mapCountry(address.country);
            mapState(address.administrative_area_level_1);
            if ($scope.registrationData.countryId) {
              $scope.autoDetectAddress.country = true;
            }
            if ($scope.registrationData.stateId) {
              $scope.autoDetectAddress.state = true;
            }
            $scope.$apply();
          } else {
            setCityFocus();
          }
        });
      });
    }

    // Step 3 methods, caturing devices added to signup
    function findMatchedOSVersion(versions) {
      let detectedOsVersion;
      let userAgentOsVersion = _.get(DeviceDetector, 'os.version');

      if (_.get(DeviceDetector, 'os.name') === Bowser.OS_MAP.Windows) {
        userAgentOsVersion = _.get(DeviceDetector, 'os.versionName');
        detectedOsVersion = _.find(versions, device => device.name === userAgentOsVersion);
      } else if (userAgentOsVersion) {
        detectedOsVersion = searchItemInArray(versions, userAgentOsVersion);

        if (!detectedOsVersion) {
          const detectedVersionArr = userAgentOsVersion.split('.');
          const partialVersionString = detectedVersionArr.splice(0, 2).join('.');
          detectedOsVersion = searchItemInArray(versions, partialVersionString);
        }
      }

      return (detectedOsVersion || null);
    }

    function initializeAutoDetectedMobileDevices() {
      $scope.isMobile = [Bowser.PLATFORMS_MAP.mobile, Bowser.PLATFORMS_MAP.tablet].includes(_.get(DeviceDetector, 'platform.type'));
      if ($scope.isMobile) {
        if (_.get(DeviceDetector, 'os.name') === Bowser.OS_MAP.iOS) {
          $scope.registrationData.mobileDevice.handsetMakerId = searchItemInArray($scope.mobileDevices, 'apple').id;
          $scope.getMobileDeviceModels();
          $scope.autoDetectDevice.mobileDevice.handsetMakerId = true;
        }
      }
    }

    function initializeAutoDetectedWebDevices() {
      $scope.isMobile = [Bowser.PLATFORMS_MAP.mobile, Bowser.PLATFORMS_MAP.tablet].includes(_.get(DeviceDetector, 'platform.type'));
      if (!$scope.isMobile) {
        const detectedDevice = searchItemInArray($scope.webDevices, _.get(DeviceDetector, 'os.name', '').toLowerCase());
        if (detectedDevice) {
          $scope.registrationData.webDevice.osId = detectedDevice.id;
          const webOsVersionsPromise = $scope.getWebOSVersions();
          $scope.autoDetectDevice.webDevice.osId = true;
          if (webOsVersionsPromise) {
            webOsVersionsPromise.then(() => {
              const detectedOsVersion = findMatchedOSVersion($scope.webOSVersions);
              if (detectedOsVersion) {
                $scope.registrationData.webDevice.osVersionId = detectedOsVersion.id;
                $scope.autoDetectDevice.webDevice.osVersionId = true;
                const langName = LanguageCodeFactory.getName(DeviceDetector.language.split('-')[0]).toLowerCase();
                const detectedLang = searchItemInArray($scope.languages, langName);
                if (detectedLang) {
                  $scope.registrationData.webDevice.osLanguageId = detectedLang.id;
                  $scope.autoDetectDevice.webDevice.osLanguageId = true;
                }
              }
            });
          }
        }
      }
    }

    $scope.getWebOSVersions = function () {
      $scope.autoDetectDevice.webDevice.osId = false;
      let donePromise = null;
      if ($scope.registrationData.webDevice.osId) {
        donePromise = StaticValuesService.osVersions($scope.registrationData.webDevice.osId).then((versions) => {
          $scope.webOSVersions = versions;
        });
      }

      $scope.registrationData.webDevice.osVersionId = '';
      $scope.autoDetectDevice.webDevice.osVersionId = false;

      $scope.registrationData.webDevice.osLanguageId = '';
      $scope.autoDetectDevice.webDevice.osLanguageId = false;

      return donePromise;
    };

    $scope.getMobileDeviceModels = function () {
      $scope.autoDetectDevice.mobileDevice.handsetMakerId = false;
      if ($scope.registrationData.mobileDevice.handsetMakerId) {
        StaticValuesService.handsetModels($scope.registrationData.mobileDevice.handsetMakerId).then((models) => {
          $scope.mobileDeviceModels = models;
        });
      }

      $scope.registrationData.mobileDevice.handsetModelId = '';
      $scope.autoDetectDevice.mobileDevice.handsetModelId = false;

      $scope.registrationData.mobileDevice.handsetOSId = '';
      $scope.autoDetectDevice.mobileDevice.handsetOSId = false;
    };

    $scope.getMobileDeviceOSVersions = function () {
      if ($scope.registrationData.mobileDevice.handsetModelId) {
        StaticValuesService.handsetOs($scope.registrationData.mobileDevice.handsetModelId).then((os) => {
          $scope.mobileDeviceOSVersions = os;
          if ($scope.isMobile) {
            const detectedOsVersion = findMatchedOSVersion($scope.mobileDeviceOSVersions);
            if (detectedOsVersion) {
              $scope.autoDetectDevice.mobileDevice.handsetOSId = true;
              $scope.registrationData.mobileDevice.handsetOSId = detectedOsVersion.id;
            } else {
              $scope.autoDetectDevice.mobileDevice.handsetOSId = false;
              $scope.registrationData.mobileDevice.handsetOSId = '';
            }
          }
        });
      } else {
        $scope.autoDetectDevice.mobileDevice.handsetOSId = false;
        $scope.registrationData.mobileDevice.handsetOSId = '';
      }
    };

    $scope.validateDevices = function () {
      const isInValidWebDevice = isEmptyKeyInObject($scope.registrationData.webDevice);
      const isInValidMobileDevice = isEmptyKeyInObject($scope.registrationData.mobileDevice);
      $scope.isInValidDevice = isInValidWebDevice && isInValidMobileDevice;

      if (!$scope.isInValidDevice) {
        // clear the incomplete device data if only one valid device is added
        if (isInValidWebDevice) {
          $scope.registrationData.webDevice = angular.copy(webDeviceObj);
          $scope.autoDetectDevice.webDevice = angular.copy(autoDetectWebObj);
        } else if (isInValidMobileDevice) {
          $scope.registrationData.mobileDevice = angular.copy(mobileDeviceObj);
          $scope.autoDetectDevice.mobileDevice = angular.copy(autoDetectMobileObj);
        }
        $scope.navigateToStep('complete');
      } else {
        $scope.$broadcast('osIdFocus');
      }
    };

    // Step-4 code/methods, capturing password and captcha
    function startSpinner() {
      $scope.isSignupInProgress = true;
      spinnerBtn = Ladda.create(document.querySelector('#laddaBtn'));
      spinnerBtn.start();
      setProgressBar(0.1, 0.3);
    }

    function setProgressBar(start, end) {
      let interval = start;
      setInterval(() => {
        if (interval < end) {
          spinnerBtn.setProgress(interval);
          interval += 0.1;
        }
      }, 100);
    }

    function stopSpinner() {
      spinnerBtn.stop();
      spinnerBtn.remove();
      $scope.isSignupInProgress = false;
    }

    function trackCompleteRegistration() {
      // this is to track completed registrations only on production
      const hostsToExclude = '(integration|stage|localhost)';
      if (!$location.$$host.match(hostsToExclude)) {
        $window.fbq('track', 'CompleteRegistration', { value: 0, currency: 'USD' });
      }
    }

    $scope.setWidgetId = function (widgetId) {
      $scope.captchaWidgetID = widgetId;
    };

    function createUser() {
      if ($rootScope.customRegistration) {
        $scope.registrationData.registrationFlowId = $rootScope.customRegistration.id;
      }

      setProgressBar(0.3, 0.8);

      return UserService.createUser($scope.registrationData).then((response) => {
        $scope.user = response.data;
        stopSpinner();
        if ($rootScope.customRegistration) {
          if ($rootScope.customRegistration.embedded_form) {
            $rootScope.customRegistration.embedded_form = $rootScope.customRegistration.embedded_form.replace('$id$', encodeURIComponent($scope.user.platform_id));
            $rootScope.customRegistration.embedded_form = $rootScope.customRegistration.embedded_form.replace('$email$', encodeURIComponent($scope.registrationData.email));
            $rootScope.customRegistration.embedded_form = $rootScope.customRegistration.embedded_form.replace('$name$', encodeURIComponent($scope.user.name));
          }
          $state.go('customsignup.outro');
          trackCompleteRegistration();
        } else {
          $location.url('/welcome?from=signup');
          trackCompleteRegistration();
        }
      }, (response) => {
        stopSpinner();
        AlertService.addDanger(ServerMessages.prettyMessage(response));
      });
    }

    $scope.showTerms = function (slug) {
      $uibModal.open({
        animation: true,
        template: termsModalTemplate,
        controller: 'ModalCtrl',
        windowClass: 'light-modal',
        resolve: {
          data: () => StaticPageService.get(slug),
        },
      });
    };

    $scope.onCaptchaResponse = function (response) {
      $scope.registrationData.captcha_response = response;

      createUser().catch(() => {
        // reset captcha on save failure
        $scope.cbExpiration();
      });
    };

    $scope.cbExpiration = function () {
      vcRecaptchaService.reload($scope.captchaWidgetID);
      $scope.registrationData.captcha_response = null;
    };

    $scope.loadUtestCaptcha = function () {
      StaticValuesService.captcha().then((response) => {
        $scope.utestCaptcha = response.data;
      });
    };

    $scope.captcha_method = globalConfig.captcha_method;
    if ($scope.captcha_method === CAPTCHA_UTEST) {
      $scope.loadUtestCaptcha();
    }

    $scope.submitForm = function (form) {
      if (!$scope.isSignupInProgress) {
        if (isValidForm(form)) {
          startSpinner();

          if ($scope.captcha_method === CAPTCHA_NONE) {
            createUser();
          } else if ($scope.captcha_method === CAPTCHA_UTEST) {
            $scope.registrationData.captcha_response = {
              challenge: $scope.utestCaptcha.challenge,
              response: $scope.utestCaptcha.response,
            };
            createUser();
          } else {
            vcRecaptchaService.execute($scope.captchaWidgetID);
          }
        } else {
          AccessibleFormService.focusFirstError(form, $scope);
        }
      }
    };

    // common methods/code called on initialization
    $scope.navigateToStep = function (step) {
      if ($rootScope.customRegistration) {
        if (_.isObject(user)) {
          $scope.user = user;
          if ($rootScope.customRegistration.embedded_form) {
            $rootScope.customRegistration.embedded_form = $rootScope.customRegistration.embedded_form.replace('$id$', encodeURIComponent($scope.user.platform_id));
            $rootScope.customRegistration.embedded_form = $rootScope.customRegistration.embedded_form.replace('$email$', encodeURIComponent($scope.user.email));
            $rootScope.customRegistration.embedded_form = $rootScope.customRegistration.embedded_form.replace('$name$', encodeURIComponent($scope.user.name));
          }
          return $state.go('customsignup.outro', { from: 'existing_user' });
        }

        return $state.go(`customsignup.${step}`);
      }

      return $state.go(`signup.${step}`);
    };

    StaticValuesService.languages().then((languages) => {
      $scope.languages = languages;
    });

    StaticValuesService.mobileDevices().then((devices) => {
      $scope.mobileDevices = devices;
      initializeAutoDetectedMobileDevices();
    });

    StaticValuesService.webDevices().then((devices) => {
      $scope.webDevices = devices;
      initializeAutoDetectedWebDevices();
    });

    StaticValuesService.countriesAndStates().then((countriesAndStates) => {
      $scope.statesByCountryId = _.mapValues(countriesAndStates.states);
      $scope.countries = countriesAndStates.countries.data;
    });

    $transitions.onSuccess({}, () => {
      $('#regs_container')[0].scrollIntoView(true);

      if ($state.$current.name.endsWith('address')) {
        $timeout(() => {
          setAddress();
        }, 50);
      }
    });

    // assigns aria-label's to ui-select dropdown focusable object
    // Change to the order of these dropdowns in the UI of sign up flow will required respective change of order in the signUpDropdownLabelsHash
    $scope.initializeUiSelectDropdownAria = function () {
      const currentState = $state.$current.name;
      const dropdown = document.getElementsByClassName('ui-select-focusser ui-select-offscreen');
      if (SIGN_UP_DROPDOWN_LABELS_HASH[currentState]) {
        for (let i = 0; i < SIGN_UP_DROPDOWN_LABELS_HASH[currentState].length; i++) {
          dropdown[i]?.setAttribute('aria-label', SIGN_UP_DROPDOWN_LABELS_HASH[currentState][i]);
        }
      }
    };

    // moves the dynamically generated input focusser element before the clear button in the DOM
    // ensuring a corrected focus order
    $scope.fixDropdownFocusOrderAccessibility = function () {
      const currentState = $state.$current.name;
      const dropdown = document.getElementsByClassName('ui-select-focusser ui-select-offscreen');
      if (SIGN_UP_DROPDOWN_LABELS_HASH[currentState]) {
        for (let i = 0; i < SIGN_UP_DROPDOWN_LABELS_HASH[currentState].length; i++) {
          const divElement = document.getElementsByClassName('ui-select-match')[i];
          const inputElement = dropdown[i];
          if (divElement && inputElement) {
            divElement.insertBefore(inputElement, divElement.firstChild);
          }
        }
      }
    };

    $scope.handleMapTab = function (e) {
      if ($scope.isGoogleMapsFocused) {
        if (['Tab'].includes(e.key) && e.shiftKey) {
          e.preventDefault();
          $scope.$broadcast('countryIdFocus');
        } else if (e.key === 'Tab') {
          e.preventDefault();
          (<HTMLElement>document.getElementsByClassName('btn-grey')[0]).focus();
        } else if (e.key === 'm') {
          $scope.isGoogleMapsFocused = false;
          (<HTMLElement>document.querySelector('[title="Show street map"]')).focus();
        }
      }
    };

    $scope.handleBackBtnTab = function (e) {
      if (['Tab'].includes(e.key) && e.shiftKey) {
        e.preventDefault();
        (<HTMLElement>document.getElementsByClassName('signup-map')[0]).focus();
      }
    };

    $scope.setIsGoogleMapsFocused = function (bool) {
      $scope.isGoogleMapsFocused = bool;
    };

    $scope.$watch(
      '$viewContentLoaded',
      () => {
        $timeout(() => {
          $('#side_banner').css({ height: $('#regs_container').height() });
          $scope.initializeUiSelectDropdownAria();
        }, 100);
      },
    );
  }
}

SignUpCtrl.$inject = ['$scope', 'UserService', '$state', 'StaticValuesService',
  'DeviceDetector', 'LanguageCodeFactory', '$q', '$document', '$timeout', 'vcRecaptchaService', '$rootScope', '$transitions', '$location',
  'leftPanelBg', 'globalConfig', 'AlertService', 'ServerMessages', 'uiGmapGoogleMapApi', '$uibModal', 'StaticPageService', 'AccessibleFormService', '$window', '$element', 'GeoService'];

export default SignUpCtrl;
