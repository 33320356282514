import template from './resources.component.html';
import controller from './resources.controller';

const resources = {
  bindings: {
    staticPage: '<',
    user: '<',
  },
  template,
  controller,
};

export default resources;
