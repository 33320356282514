import _ from 'lodash';
import ProjectService from '../components/project/project.service';
import ProjectCtrl from '../project/home/project.controller';
import project from '../project/home/project.component';
import ProjectListCtrl from '../project/list/project-list.controller';
import projectListTemplate from '../project/list/list.html';
import UserResolver from '../authentication/user-resolver';

const ProjectsRouter = {
  route: (module) => {
    module
      .service('ProjectService', ProjectService)
      .component('project', project)
      .controller('ProjectCtrl', ProjectCtrl)
      .controller('ProjectListCtrl', ProjectListCtrl);

    module.config(['$stateProvider', ($stateProvider) => {
      $stateProvider
        .state('projects', {
          url: '/projects',
          controller: ['$state', '$scope', ($state, $scope) => ($scope.$state = $state)],
          abstract: true,
          parent: 'root',
          template: '<div ui-view="projects-index" ng-show="$state.includes(\'project\')"></div><div ui-view="projects-show" ng-show="$state.includes(\'singleproject\')" ></div>',
        })
        .state('project', {
          url: '?testType&tab&country',
          parent: 'projects',
          reloadOnSearch: false, // this means: reloadOnQueryParamChange
          views: {
            'projects-index': {
              component: 'project',
            },
          },
          sticky: true,
          resolve: {
            headerData: ['StaticValuesService', 'featureFlags', (StaticValuesService, featureFlags) => {
              const unauthDescriptionText = featureFlags.isOn('UNAUTHENTICATED_UTEST_REDESIGN_2025')
                ? 'Whether you’re an everyday technology user or a subject-matter expert, you can start improving digital products as a paid tester with uTest. Browse opportunities below and sign up to receive even more in your inbox.'
                : 'Please view a small fraction of the available paid project opportunities uTest has to offer! Sign up today and fill out your tester profile to receive more projects.';
              const defaultData = {
                title: 'Projects Board',
                description: 'Please know, the Projects Board shows only a small fraction of the paid project opportunities uTest has to offer! Increase your chances at being matched with paid project opportunities by keeping your Tester Profile complete and up-to-date! <br> <br>\n'
                + '        We need your help! The projects listed below are ones which we were unable to automatically identify testers for due to unique requirements. Read through the project details carefully and apply for any project you are a match for!',
                unauthDescription: unauthDescriptionText,
                coverBg: {
                  small: 'assets/images/hero/projects-bg-xs.jpg',
                  large: 'assets/images/hero/projects-bg.jpg',
                },
                unauthCoverBg: {
                  wide: 'assets/images/hero/unauth-background.svg',
                  medium: 'assets/images/hero/unauth-background-s.svg',
                  small: 'assets/images/hero/unauth-background-xs.svg',
                },
              };
              return StaticValuesService.pageData('projects').then((response) => {
                response.data = _.defaultsDeep(response.data, defaultData);
                return response;
              }, _error => ({
                data: defaultData,
              }));
            }],
            coverBg: ['ImageDataService', 'headerData', (ImageDataService, headerData) => (headerData.data.coverBg.small === 'assets/images/hero/projects-bg-xs.jpg'
              ? ImageDataService.getData(headerData.data.coverBg.small) : headerData.data.coverBg.small)],
            user: UserResolver,
            projectInfo: ['ProjectService', (ProjectService) => ProjectService.info().then(({ data }) => data)],
            title: [() => 'uTest Projects'],
          },
        })
        .state('singleproject', {
          url: '/:projectId',
          parent: 'projects',
          views: {
            'projects-show': {
              component: 'singleProject',
            },
          },
          resolve: {
            project: ['ProjectService', '$transition$', '$state', function (ProjectService, $transition$, $state) {
              const newPromise = ProjectService.find($transition$.params().projectId);
              newPromise.catch(() => $state.go('project'));
              return newPromise;
            }],
            title: ['project', project => `uTest Project - ${project.data.title}`],
            meta: ['project', 'title', 'Meta', function (project, title, Meta) {
              Meta.updateTag({ name: 'og:title', property: 'og:title', content: `${title} - via @uTest` });
            }],
            user: UserResolver,
          },
        })
        .state('projectlist', {
          url: '/projects/t/:tags',
          template: projectListTemplate,
          controller: 'ProjectListCtrl',
          parent: 'root',
          resolve: {
            projects: ['$transition$', 'ProjectService', 'FeedStore', function ($transition$, ProjectService, FeedStore) {
              return new FeedStore(_.bind(ProjectService.feed, ProjectService), { sort: '-published_at', filter: { state: 'published', tags: $transition$.params().tags.split('+') } }).load();
            }],
            title: [() => 'uTest Projects'],
          },
        });
    }]);
  },
};

export default ProjectsRouter;
