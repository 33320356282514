import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { TagBtnComponent } from './tag-btn/tag-btn.component';
import { IdentityVerificationBannerComponent } from './identity-verification-banner/identity-verification-banner.component';

@NgModule({
  imports: [
    CommonModule,
    UIRouterUpgradeModule,
  ],
  declarations: [
    TagBtnComponent,
    IdentityVerificationBannerComponent,
  ],
  exports: [
    TagBtnComponent,
    IdentityVerificationBannerComponent,
  ],
})
export class CommonComponentsModule { }
