export default `
<div class="stats-tab">
  <div class="profile-about" ng-if="user.professional_summary">
    <div class="c10 about">
      <h4>About Me</h4>
      <p ng-show="user.professional_summary && user.professional_summary != 'undefined'" ng-bind-html="user.professional_summary | uMarkdown"></p>
      <p ng-bind-html="'Tester has not yet shared anything about themselves :slight_frown:' | uMarkdown" ng-show="!user.professional_summary || user.professional_summary == 'undefined'"></p>
    </div>
  </div>
  <hr aria-hidden="true" ng-show="user.professional_summary">
      <h2 ng-show="!user.is_employee && user.platform_badges">TESTING TYPE BADGES:</h2>
  <div class="platform-badges-section text-center" ng-show="!user.is_employee && user.platform_badges">
    <div class="platform-badges-item">
      <div role="img" aria-labelledby="badgeDetails1">
      <img ng-src="assets/images/badges/{{user.platform_badges['localization']}}Badge.svg" class="platform-badges-item_top" alt="">
      <img title="Localization" src="assets/images/badges/localizationLarge.svg" alt="">
      <div id="badgeDetails1" class="platform-badges-item__details">
        <span class="platform-badges-item__details-rating">{{user.platform_badges['localization']}} Tester</span>
        <span class="platform-badges-item__details-type">Localization</span>
      </div>
      </div>
    </div>
    <div class="platform-badges-item">
      <div role="img" aria-labelledby="badgeDetails2">
      <img ng-src="assets/images/badges/{{user.platform_badges['usability']}}Badge.svg" class="platform-badges-item_top" alt="">
      <img title="Usability" src="assets/images/badges/usabilityLarge.svg" alt="">
      <div id="badgeDetails2" class="platform-badges-item__details">
        <span class="platform-badges-item__details-rating">{{user.platform_badges['usability']}} Tester</span>
        <span class="platform-badges-item__details-type">Usability</span></div>
      </div>
    </div>
    <div class="platform-badges-item">
      <div role="img" aria-labelledby="badgeDetails3">
      <img ng-src="assets/images/badges/{{user.platform_badges['security']}}Badge.svg" class="platform-badges-item_top" alt="">
      <img title="Security" src="assets/images/badges/securityLarge.svg" alt="">
      <div id="badgeDetails3" class="platform-badges-item__details">
        <span class="platform-badges-item__details-rating">{{user.platform_badges['security']}} Tester</span>
        <span class="platform-badges-item__details-type">Security</span>
      </div>
      </div>
    </div>
    <div class="platform-badges-item">
      <div role="img" aria-labelledby="badgeDetails4">
      <img ng-src="assets/images/badges/{{user.platform_badges['load']}}Badge.svg" class="platform-badges-item_top" alt="">
      <img title="Load" src="assets/images/badges/loadLarge.svg" alt="">
      <div id="badgeDetails4" class="platform-badges-item__details">
        <span class="platform-badges-item__details-rating">{{user.platform_badges['load']}} Tester</span>
        <span class="platform-badges-item__details-type">Load</span>
      </div>
      </div>
    </div>
    <div class="platform-badges-item">
      <div role="img" aria-labelledby="badgeDetails5">
      <img ng-src="assets/images/badges/{{user.platform_badges['functional']}}Badge.svg" class="platform-badges-item_top" alt="">
      <img title="Functional" src="assets/images/badges/functionalLarge.svg" alt="">
      <div id="badgeDetails5" class="platform-badges-item__details">
        <span class="platform-badges-item__details-rating">{{user.platform_badges['functional']}} Tester</span>
        <span class="platform-badges-item__details-type">Functional</span>
      </div>
      </div>
    </div>
    <img class="platform-badges-item-block" ng-if="user.plus_tester_rating_id" src="assets/images/badges/community-plus.jpg">
  </div>
  <hr aria-hidden="true" ng-show="!user.is_employee && user.platform_badges" >
  <h2 ng-show="!user.is_employee && user.platform_badges">PLATFORM STATISTICS:</h2>
  <div class="stat-date-section">
      <stat-date role="img" aria-labelledby="statDateDetails1" ng-show="user.site_stats.joined_at" version="2" label="JOIN DATE" date="user.site_stats.joined_at"></stat-date>
      <stat-date role="img" aria-labelledby="statDateDetails2"ng-show="user.site_stats.last_login_at" version="0" label="LAST LOGIN" date="user.site_stats.last_login_at"></stat-date>
      <stat-date role="img" aria-labelledby="statDateDetails3" ng-show="user.site_stats.last_commented_at" version="1" label="LAST COMMENT" date="user.site_stats.last_commented_at"></stat-date>
  </div>
  <hr aria-hidden="true" ng-show="user.site_stats.joined_at || user.site_stats.last_login_at || user.site_stats.last_commented_at">
  <h2 ng-show="user.site_stats.joined_at || user.site_stats.last_login_at || user.site_stats.last_commented_at">COMMUNITY ACTIVITY:</h2>
  <div class="row">
    <div class="text-center" style="padding-top:30px;">
      <label for="timeRange">
        Activity Time Range
      </label>
      <select 
        name="timeRange"
        id="timeRange"
        ng-model="stats.active" 
        ng-options="statOption as statOption.label for statOption in stats.options">
      </select>
    </div>
    <div class="col-sm-6">
      <div class="stat-items-grid">
        <div tabindex=0 class="stat-item stat-item-legend" ng-class="'stat-item-legend--type-status_count'" role="figure" aria-label="{{stats.active.totals.status_count}} STATUS UPDATES">
          <span odometer="stats.active.totals.status_count"></span><small class="text-muted">STATUS UPDATES</small>
        </div>
        <div tabindex=0 class="stat-item stat-item-legend" ng-class="'stat-item-legend--type-article_count'" role="figure" aria-label="{{stats.active.totals.article_count}} ARTICLES">
          <span odometer="stats.active.totals.article_count"></span><small class="text-muted">ARTICLES</small>
        </div>
        <div tabindex=0 class="stat-item stat-item-legend" ng-class="'stat-item-legend--type-post_count'" role="figure" aria-label="{{stats.active.totals.post_count}} FORUM POSTS">
          <span odometer="stats.active.totals.post_count"></span><small class="text-muted">FORUM POSTS</small>
        </div>
        <div tabindex=0 class="stat-item stat-item-legend" ng-class="'stat-item-legend--type-comment_count'" role="figure" aria-label="{{stats.active.totals.comment_count}} COMMENTS">
          <span odometer="stats.active.totals.comment_count"></span><small class="text-muted">COMMENTS</small>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div tabindex=0 class="stat-line-chart" role="figure" aria-label="Activity line chart">
        <div ng-repeat="(i, x) in graphBuilder track by $index" class="stat-line-chart_item stat-line-chart_item--12">
          <div class="stat-line-chart_item_inner" ng-style="{height: getPercent(stats.active.data[i].valuesSum, stats.active.graphItemMax)}" >
            <div ng-repeat="(gItemKey, gItemVal) in stats.active.data[i].values track by $index" aria-hidden="{{gItemVal == 0? true: false}}" ng-style="{height: getPercent(gItemVal, stats.active.data[i].valuesSum) }" class="stat-line-chart_value" ng-class="'stat-line-chart_value--type-' + gItemKey" title="{{prepareChartReadableLabel(gItemKey)}}" aria-label="{{prepareChartReadableLabel(gItemKey)}}"></div>
          </div>
        </div>
        <div ng-repeat="gItem in stats.active.data" class="stat-line-chart_label stat-line-chart_item--12" aria-hidden="true" ng-bind="gItem.label"></div>
      </div>
    </div>
  </div>
  <hr aria-hidden="true">
  <h2 ng-show="user.badges">COMMUNITY BADGES:</h2>
  <div class="grid social-badges" ng-show="user.badges" >
    <div class="c3 badge-item" ng-repeat="badge in user.badges | orderBy: '-points'">
      <div bg-img="assets/images/badges/{{badgePresentation[badge.badge_type].icon}}" class="badge-img"></div>
      <div class="badge-item-title">{{badgePresentation[badge.badge_type].name}}</div>
      <div class="badge-item-point">+{{badge.points}} points</div>
    </div>
  </div>
</div>
`;
