import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { UnauthenticatedHomeComponent } from './unauthenticated-home/unauthenticated-home.component';
import { WistiaPopoverPlayerComponent } from '../common/wistia-popover-player/wistia-popover-player.component';
import { AccordionComponent } from '../common/accordion/accordion.component';
import { AccordionGroupComponent } from '../common/accordion/accordion-group.component';
import { ContentSliderComponent } from '../common/contentslider/content-slider.component';
import { FirstWordLowerCase } from '../pipes/first-word-lower-case';
import { FourOhFourComponent } from '../four-oh/four-oh-four.component';
import { FourOhOneComponent } from '../four-oh/four-oh-one.component';

@NgModule({
  imports: [
    CommonModule,
    UIRouterUpgradeModule.forRoot(),
  ],
  declarations: [
    FirstWordLowerCase,
    UnauthenticatedHomeComponent,
    WistiaPopoverPlayerComponent,
    AccordionGroupComponent,
    AccordionComponent,
    ContentSliderComponent,
    FourOhFourComponent,
    FourOhOneComponent,
  ],
  exports: [
    UnauthenticatedHomeComponent,
    FourOhFourComponent,
    FourOhOneComponent,
  ],
  entryComponents: [
    UnauthenticatedHomeComponent,
  ],
})
class HomeModule {}

export { HomeModule };
