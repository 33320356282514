import _ from 'lodash';
import template from './overlay.html';

/**
 *
 * Directive for showing a modal/overlay element
 * As of UT-2691 this should be used in conjunction with the native <dialog> element
 *
 * Using <dialog> as opposed to a <div> with a class of .overlay allows us to use
 * browser defined a11y features for modals, and to use the simple API of `showModal()` and `close()`
 * Note: these features include native focus trapping to the dialog, and autofocusing the first focusable element
 *
 * TODO: css classes for .overlay should be updated to select for <dialog> and <dialog open>
 */
const overlay = function () {
  return {
    restrict: 'C',
    transclude: true,
    template,
    link(scope) {
      // trigger overlay effect
      scope.openOverlay = function (target) {
        // if there's no target specified, default to selecting 'dialog'
        // elements in tree
        target = (typeof target === 'undefined') ? 'dialog' : `#${target}`;

        const el = $(target);
        // jquery selects a list of elements matching the selector,
        // grab the first selected <dialog> and call native `showModal()`

        (el[0] as HTMLDialogElement).showModal();
      };

      scope.closeOverlay = function (target) {
        // default selector to opened dialog elements if no target is specified
        target = _.isUndefined(target) ? 'dialog[open]' : `#${target}`;

        const el = $(target);
        // jquery selects a list of elements matching the selector,
        // grab the first selected <dialog> and call native `close()`
        (el[0] as HTMLDialogElement).close();
      };
    },
  };
};

export default overlay;
